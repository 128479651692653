// this is a no-op function, every text in the document should be handled by this function
// to make it easier to migrate later
// details integration can be done later
// https://locize.com/blog/react-i18next/#interpolation-pluralization
function t(input: string) {
  return input;
}

/**
 * @deprecated Use `next-intl`
 */
export function pluralize(
  input: number,
  singularForm: string,
  pluralForm: string,
) {
  return input === 0 || input > 1 ? t(pluralForm) : t(singularForm);
}

const stubTranslation = { t, pluralize };

/**
 * @deprecated Use `next-intl`
 */
export function useTranslation() {
  return stubTranslation;
}
// base on the discussion that here had before, we chose the configurationType 2
// so we don't need to change anything now
// const configurationType1 = {
//   application: {
//     homepage: {
//       blocks: [
//         {
//           id: 'abc321-unique-block-id',
//           type: 'hero',
//           data: {
//             title: {
//               type: 'translation',
//               data: {
//                 'en-US': 'Hello World',
//                 'ko-Kr': 'some text',
//               },
//             },
//             description: {
//               type: 'translation',
//               data: {
//                 'en-US': 'Hello World',
//                 'ko-Kr': 'some text',
//               },
//             },
//           },
//         },
//         {
//           id: 'xyz123-unique-block-id',
//           type: 'carousell',
//           data: [
//             {
//               imageUrl: 'some-url',
//               description: '',
//             },
//           ],
//         },
//       ],
//       order: ['abc321-unique-block-id', 'xyz123-unique-block-id'],
//     },
//   },
//   translations: {
//     data: {
//       'en-US': {
//         'abc321-unique-block-id': {
//           title: 'some title',
//           description: 'lorem isump',
//         },
//         'xyz123-unique-block-id': {
//           '0': {
//             description: 'orem isump',
//           },
//         },
//       },
//       'ko-KR': {
//         'abc321-unique-block-id': {
//           title: 'some title',
//           description: 'lorem isump',
//         },
//         'xyz123-unique-block-id': {
//           '0': {
//             description: 'orem isump',
//           },
//         },
//       },
//     },
//   },
// };

// type BlockComponent<TComponentType extends string, TData> = {
//   id: string,
//   type: TComponentType,
//   data:TData,
// };

// type HeroComponent = BlockComponent<'hero',{
//   variant: string,
//   title: string,
//   description: string,
// }>

// type CarouselComponent = BlockComponent<'carousel',Array<{
//   imageUrl: string,
//   description: string,
// }>>

// type AllComponents = HeroComponent | CarouselComponent;

// type Config = {
//   blocks: Record<string, AllComponents>
// }

// const blockConfig: Config = {
//   blocks: {
//     'abc-123': {
//       id: 'abc-123',
//       type: 'hero',
//       data: {
//         variant: '1',
//         title: '',
//         description: '',
//       },
//     },
//     }
//   }

//   const a = blockConfig.blocks['abc-123']

//   if(a.type === 'hero'){
//     a
//   }

// const configurationType2 = {
//   application: {
//     homepage: {
//       blocks: {

//         'abc321-unique-block-id': {
//           id: 'abc321-unique-block-id',
//           type: 'hero',
//           data: {
//             variant: 'variant1',
//             title: '',
//             description: '',
//           },
//         },
//         'xyz123-unique-block-id':{
//           id: 'xyz123-unique-block-id',
//           type: 'carousell',
//           data: [
//             {
//               imageUrl: 'some-url',
//               description: '',
//             },
//           ],
//         },
//       },
//       order: ['abc321-unique-block-id', 'xyz123-unique-block-id'],
//     },
//   },
//   translations: {
//     data: {
//       'en-US': {
//         'abc321-unique-block-id': {
//           title: 'some title',
//           description: 'lorem isump',
//         },
//         'xyz123-unique-block-id': {
//           '0': {
//             description: 'orem isump',
//           },
//         },
//       },
//       'ko-KR': {
//         'abc321-unique-block-id': {
//           title: 'some title',
//           description: 'lorem isump',
//         },
//         'xyz123-unique-block-id': {
//           '0': {
//             description: 'orem isump',
//           },
//         },
//       },
//     },
//   },
// };

// const configurationType3 = {
//   application: {
//     homepage: {
//       blocks: [
//         {
//           id: 'abc321-unique-block-id',
//           type: 'hero',
//           data: {
//             title: 'homepage.hero.title',
//             description: 'homepage.hero.title',
//           },
//         },
//         {
//           id: 'abc321-unique-block-id',
//           type: 'hero',
//           data: {
//             variant: '1',
//             title: 'abc321-unique-block-id.homepage.hero.title',
//             description: 'abc321-unique-block-id.homepage.hero.title',
//           },
//         },
//         {
//           id: 'xyz123-unique-block-id',
//           type: 'carousell',
//           data: [
//             {
//               imageUrl: 'some-url',
//               description:
//                 'xyz123-unique-block-id.homepage.carousell.image-description.0',
//             },
//             {
//               imageUrl: 'some-url',
//               description:
//                 'xyz123-unique-block-id.homepage.carousell.image-description.1',
//             },
//           ],
//         },
//       ],
//       order: ['abc321-unique-block-id', 'xyz123-unique-block-id'],
//     },
//   },
//   translations: {
//     data: {
//       'en-US': {
//         'abc321-unique-block-id': {
//           'homepage.hero.title': 'some title',
//         },
//       },
//       'ko-KR': {
//         'abc321-unique-block-id': {
//           title: 'some title',
//           description: 'lorem isump',
//         },
//         'xyz123-unique-block-id': {
//           '0': {
//             description: 'orem isump',
//           },
//         },
//       },
//     },
//   },
// };

// //
// // fixed translation
// // for 1.0 rc static files

// // 1.0 interpolation
// ('hello ${user.name}');

// const data = {
//   user: {
//     name: '',
//   },
//   brandName: '',
// };
// function resolveInterpolation(string, data) {
//   // regex
//   const tokens = ['hello', ' ', `${data['user.name']}`];
// }

// // What's next

// - Fixed
// - fixed translation in next.js
// - pro

// - Custom
// - resolver type2 + poc

// const homepage= [
//   {
//     id: 'abc321-unique-block-id',
//     type: 'hero',
//     data: {
//       variant: 'variant1',
//       title: 'text',
//       description: 'lorem isump',
//     },
//   },
//   {
//     id: 'abc321-unique-block-id',
//     type: 'carousell',
//     data: [
//       {
//         imageUrl: 'some-url',
//         description: 'lorem isump',
//       }
//     ],
//   },

// ]
