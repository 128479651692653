'use client';

import { ComponentPropsWithoutRef } from 'react';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/tailwind';

import { Input } from './input';

export const SearchInput = ({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Input>) => {
  return (
    <Input
      icon={<Icon name="magnifying-glass" className="text-neutral-500" />}
      {...props}
      className={cn(className, 'bg-neutral-100 pr-8')}
    />
  );
};
SearchInput.displayName = 'SearchInput';
