import { z } from 'zod';

export const travelTierSchema = z.object({
  tier: z.number(),
  cashPayment: z.number(),
  cashPaymentInUsd: z.number(),
  pointsPayment: z.number(),
  points: z.number().optional(),
  pointsEarned: z.number().optional(),
});

export type TravelTier = z.infer<typeof travelTierSchema>;
